import { BrowserRouter, Route } from "react-router-dom";
import DocValidation from "./docValidation";


const App = () => {

    return (
        <BrowserRouter>
            
        <Route
                path= "/docvalidation/:code"
            exact
            component={DocValidation }
            />
        </ BrowserRouter>
        );
};

export default App;
