import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useRouteMatch } from "react-router-dom";

const cyberityWebSdkContainerId = "cyberity-websdk-container";

const DocValidation = () => {
    const match: { params: { code: string } } = useRouteMatch();
    const code = match.params.code;
    const [result, setResult] = useState<any>();
    const requestToken = useCallback(async () => {
        if (code) {
            axios.get(`/api/docvalidation/params/${code}`).then(({ data }) => {
                return data.token;
            });
        }
        return "";
    }, [code]);

    useEffect(() => {
        if (code) {
            axios.get(`/api/docvalidation/params/${code}`).then(({ data }) => {
                setResult(data);
            });
        }
    }, [code]);

    useEffect(() => {
        if (result) {
            /// https://developers.cyberity.ru/web-sdk/#frontend-integration
            const sns = window.snsWebSdk.init(result.token, async () => await requestToken())
                .withBaseUrl("https://api.cyberity.ru")
                .withConf({
                    // @ts-ignore
                    //lang: result.language,
                    lang: "ru",
                });
            const snsWebSdkInstance = sns.build();
            snsWebSdkInstance.launch(`#${cyberityWebSdkContainerId}`);
        }
    }, [result, requestToken]);

    return <div id={cyberityWebSdkContainerId} />;
};

export default DocValidation;